
//import { breakpoints } from '../variables.yml';

import 'focus-visible';
import EmblaCarousel from 'embla-carousel';
//import '.vendor/embla-survey';
// import './vendor/lib.js';
// import { whatever } from './vendor/lib.js';
// import { getWindowWidth } from './helpers.js';

export * from './CookieConsentManager.js';

( () => { /****  Testimonial slider  ****/

	const embla_node_testimonial = document.querySelector( '.testimonial .embla' );
	const options_testimonial = { loop: false, align: 'start' };
	if ( embla_node_testimonial == null ) {
		return;
	}

	//specify embla function
	const slider_testimonial = EmblaCarousel( embla_node_testimonial, options_testimonial );
	const button_prev = document.querySelector( '.testimonial__button--prev' );
	const button_next = document.querySelector( '.testimonial__button--next' );

	//call button functions
	button_prev.addEventListener( 'click', () => {
		slider_testimonial.scrollPrev();
	}, false );

	button_next.addEventListener( 'click', () => {
		slider_testimonial.scrollNext();
	}, false );

	//modify button styles
	slider_testimonial.on( 'select', () => {
		resetButtonState( button_prev, slider_testimonial.canScrollPrev() );
		resetButtonState( button_next, slider_testimonial.canScrollNext() );
	} );

	function resetButtonState( element, state ) {
		if ( state ) {
			element.classList.add( 'testimonial__button--enabled' );
		} else {
			element.classList.remove( 'testimonial__button--enabled' );
		}
	}
} )();

( () => { /****  Survey slider  ****/

	const embla_node_survey = document.querySelector( '.survey' );
	if ( embla_node_survey == null ) {
		return;
	}

	//specify embla function
	const options_survey = {
		loop: false,
		align: 'start',
		draggable: false,
		startIndex: parseInt( embla_node_survey.dataset.startIndex, 10 ),
	};
	const slider_survey = EmblaCarousel( embla_node_survey, options_survey );

	//call back button function
	const button_back = document.querySelectorAll( '.survey__button-embla--back' );
	for ( let i=0; i<button_back.length; i++ ) {
		button_back[i].addEventListener( 'click', () => {
			slider_survey.scrollPrev();
		}, false );
	}

	//call next button function
	const button_forward = document.querySelectorAll( '.survey__button-embla--forward' );
	for ( let j=0; j<button_forward.length; j++ ) {
		button_forward[j].addEventListener( 'click', () => {
			slider_survey.scrollNext();
		}, false );
	}

	//update progress styles for both buttons being clicked
	slider_survey.on( 'select', () => {
		updateProgressStyle();
	} );
	// Update once on load just in case we've started on a later slide.
	updateProgressStyle();

	//get current slide for both button events
	function getCurrentSlide() {
		const current_slide = slider_survey.slideNodes()[
			slider_survey.selectedScrollSnap()
		];
		//alert( current_slide.dataset.stepIndex );
		return current_slide.dataset.stepIndex;
	}

	//update progress component styles
	function updateProgressStyle() {
		const step_index= parseInt( getCurrentSlide() );
		const step = step_index + 1;
		document.getElementById( 'survey__progress__step-p' ).textContent = 'Step ' + step + ' of 4';

		//change circle styles
		const progress_circles = document.getElementsByClassName( 'survey__progress__step-circle__item' );

		for ( let k=0; k<progress_circles.length; k++ ) {
			progress_circles[k].classList.remove( 'survey__progress__step-circle__item--current' );
			progress_circles[k].classList.remove( 'survey__progress__step-circle__item--completed' );
			if ( k===step_index ) {
				progress_circles[k].classList.add( 'survey__progress__step-circle__item--current' );
				continue;
			}
			if ( k<step_index ) {
				progress_circles[k].classList.add( 'survey__progress__step-circle__item--current' );
				progress_circles[k].classList.add( 'survey__progress__step-circle__item--completed' );
				continue;
			}
		}
		//change line styles
		const line = document.getElementsByClassName( 'survey__progress__line' );
		for ( let l=0; l<line.length; l++ ) {
			if ( l < step_index ) {
				line[l].classList.add( 'survey__progress__line--completed' );
			} else {
				line[l].classList.remove( 'survey__progress__line--completed' );
			}
		}

	}

} )();

( () => { /****  Header  mobile icon ****/
	const button_header = document.querySelector( '.header__mobile-nav-toggle' );

	button_header.addEventListener( 'click', () => {
		document.body.classList.toggle( 'mobile-nav-open' );
	}, false );

} )();
